import * as React from 'react';
import { useState, useMemo } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../redux/@types';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    IconButton,
    Button,
    TablePagination,
    Tooltip,
} from "@mui/material";
import {
    ArrowForward as ArrowForwardIcon,
    Edit as EditIcon,
    Add as AddIcon,
    RestorePage as RestorePageIcon,
    NoteAdd as NoteAddIcon,
    FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import { formsActions, formsActionsAsync } from "../redux/actions/formsActions";
import { analysesByClassificationSelector } from '../redux/selectors/analysesByClassificationSelector';
import { classificationCountSelector } from '../redux/selectors/classificationCountSelector';
import {
    ANALYSIS_STATUS, 
    REPORT_STATUS,
    ANALYSIS_STATUS_TEXT,
    REPORT_STATUS_TEXT
} from '../constants';
import { reportsActionsAsync } from '../redux/actions/reportsActions';
import { analysesActionsAsync } from '../redux/actions/analysesActions';
import { formatDate } from "../utils/formatDate";


const rowsPerPageOptions = [10, 25, 50];

const headCells: {
    id: string,
    numeric: boolean,
    label: string,
    width: string,
    sortable: boolean,
    align?: "right" | "left" | "center"
}[] = [
    {
        id: 'reportName',
        numeric: false,
        label: 'NAME',
        width: '20%',
        sortable: true
    },
    {
        id: 'username',
        numeric: false,
        label: 'USER',
        width: '15%',
        sortable: true
    },
    {
        id: 'reportStatus',
        numeric: false,
        label: 'STATUS',
        width: '20%',
        sortable: true
    },
    {
        id: 'dateSubmitted',
        numeric: false,
        label: 'DATE SUBMITTED',
        width: '20%',
        sortable: true
    },
    {
        id: 'actions',
        numeric: false,
        label: 'ACTIONS',
        align: 'right',
        width: '25%',
        sortable: false
    },
]

interface ReportOverviewCardProps extends RouteComponentProps {
    classificationId: string,
}

const ReportOverviewCard: React.FC<ReportOverviewCardProps> = ({ classificationId }) => {
    const dispatch = useDispatch();

    // Get report type details
    const count = useSelector(classificationCountSelector(classificationId));
    const classification = useSelector((state: IState) => state.forms.classifications[classificationId]);
    const analyses = useSelector(analysesByClassificationSelector(classification.classificationId));
    const reports = useSelector((state: IState) => state.reports.reports);
    const users = useSelector((state: IState) => state.users.users);
    const user = useSelector((state: IState) => state.users.user);

    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [page, setPage] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<"reportName" | "username" | "dateSubmitted" | "reportStatus">("dateSubmitted");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
    const [tempDisable, sTempDisable] = useState<boolean>(false); 

    const rows = useMemo(() => {
        const rows = analyses.map(analysis => {
            const analysisReports = reports.filter(r => r.analysis_id === analysis.analysis_id);
            const report = analysisReports.length > 0 && analysisReports.reduce((a, b) => new Date(a.date_submitted) > new Date(b.date_submitted) ? a : b);
            return {
                reportName: analysis.response 
                    && typeof analysis.response === 'object' 
                    // && analysis.response.project_name
                    && (
                        analysis.response.project_name
                        // TODO create type of TERA analysis response
                        // @ts-ignore
                        || analysis.response.Q3 && analysis.response.Q3.answer && analysis.response.Q3.answer[0]
                    ) || analysis.description || "Untitled",
                username: user && user.userId === analysis.updated_by && user.username // Self last updated by
                    || users[analysis.updated_by] && users[analysis.updated_by].username // Last updated by
                    || user && user.userId === analysis.user_id && user.username // Self created by
                    || users[analysis.user_id] && users[analysis.user_id].username || analysis.user_id, // Created by
                analysisStatus: analysis.status && analysis.status,
                reportStatus: report && report.status || null,
                analysisId: analysis.analysis_id,
                reportId: report && report.report_id || null,
                dateCreated: analysis.date_created,
                // dateSubmitted: report && report.date_submitted || null
                dateSubmitted: analysis.date_submitted || null,
                reportSubmitted: report && report.date_submitted || null,
                classificationId: analysis.classification_id,
            }
        });
        // rows.sort((a, b) => {
        //     if (!a.dateCreated) return 1;
        //     if (!b.dateCreated) return -1;
        //     return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        // });
        return rows;
    }, [analyses, reports]);

    const sorted = useMemo(() => {
        let sorted = rows;
        sorted.sort((a, b) => {
            if (!a.dateCreated) return 1;
            if (!b.dateCreated) return -1;
            return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });
        if (orderBy === "dateSubmitted") {
            sorted.sort((a, b) => {
                if (!a.dateSubmitted) return 1;
                if (!b.dateSubmitted) return -1;
                    return sortOrder === "desc" ? new Date(b.dateSubmitted).getTime() - new Date(a.dateSubmitted).getTime()
                        : new Date(a.dateSubmitted).getTime() - new Date(b.dateSubmitted).getTime(); 
            });
            // console.log("sorted by submitted date");
        } else if (orderBy === "reportStatus") {
            const sortAnalysisStatus = () => {
                sorted.sort((a, b) => {
                    if (!a.analysisStatus && !b.analysisStatus) return 0;
                    if (sortOrder === 'desc') {
                        if (!a.analysisStatus) return 1;
                        if (!b.analysisStatus) return -1;
                        return b.analysisStatus - a.analysisStatus;
                    } else {
                        if (!a.analysisStatus) return -1;
                        if (!b.analysisStatus) return 1;
                        return a.analysisStatus - b.analysisStatus;
                    }
                });
            }
            const sortReportStatus = () => {
                sorted.sort((a, b) => {
                    if (!a.analysisStatus || !b.analysisStatus) return 0;
                    if (!a.reportStatus && !b.reportStatus) return 0;
                    if (sortOrder === 'desc') {
                        if (!a.reportStatus) return 1;
                        if (!b.reportStatus) return -1;
                        return b.reportStatus - a.reportStatus;
                    } else {
                        if (!a.reportStatus) return -1;
                        if (!b.reportStatus) return 1;
                        return a.reportStatus - b.reportStatus;
                    }
                });
            }
            if (sortOrder === 'desc') {
                sortAnalysisStatus();
                sortReportStatus();
            } else {
                sortReportStatus();
                sortAnalysisStatus();
            }
            // console.log("sorted by submitted date");
        } else {
            sorted.sort((a, b) => {
                if (!a[orderBy]) return 1;
                if (!b[orderBy]) return -1;
                // Case insensitive sort
                const aOrderBy = a[orderBy].toLowerCase();
                const bOrderBy = b[orderBy].toLowerCase();
                if (sortOrder === "desc") {
                    if (aOrderBy < bOrderBy) return -1;
                    if (aOrderBy > bOrderBy) return 1;
                } else {
                    if (aOrderBy > bOrderBy) return -1;
                    if (aOrderBy < bOrderBy) return 1;
                }
                return 0;
            });
            // console.log("sorted by other");
        }
        return sorted;
    }, [rows, sortOrder, orderBy]);

    const handleTempDisable = () => {
        sTempDisable(true);
        setTimeout(() => {
            sTempDisable(false);
        }, 10000); // Button reset in 10 seconds
    }
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    }
    const createNewReport = () => {
        dispatch(formsActionsAsync.createAnalysis.request({ classificationId: classification.classificationId }));
        handleTempDisable();
    }
    const generateReport = (description: string, analysisId: string, classificationId: string) => {
        dispatch(reportsActionsAsync.createReport.request({ description, analysisId, classificationId }));
        handleTempDisable();
    }
    const downloadReport = (reportId: string | null) => {
        if (!reportId) return;
        dispatch(reportsActionsAsync.downloadReport.request({ reportId }));
    }
    const editAnalysis = (analysisId: string) => {
        const analysis = analyses.find(analysis => analysis.analysis_id === analysisId);
        if (!analysis) return;
        dispatch(analysesActionsAsync.getAnalysis.request({ analysisId: analysis.analysis_id }));
        // Continue questionnaire if unfinished else create duplicate with copied answers
        if (Number(analysis.status) === ANALYSIS_STATUS.STARTED) return dispatch(formsActions.editAnalysis(analysis));
        else return dispatch(formsActionsAsync.duplicateAnalysis.request({
            classificationId,
            analysisId: analysis.analysis_id
        }));
    }
    const viewAnalysis = (analysisId: string) => {
        const analysis = analyses.find(analysis => analysis.analysis_id === analysisId);
        if (!analysis || classification.majorType !== "AHVVA") return;
        dispatch(analysesActionsAsync.getAnalysis.request({ analysisId: analysis.analysis_id }));
        dispatch(formsActions.viewAhvva(analysis));
    }

    return (
        <Box>
            <Card>
                <CardContent sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography variant="h4">{classification.description}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{
                            marginRight: '12px'
                        }} variant="h6">Remaining: {count?.remaining || 0}</Typography>
                        <Button
                            variant="contained"
                            onClick={createNewReport}
                            // disabled={
                            //     !count ||
                            //     count && typeof count.remaining === 'number' && count.remaining < 1
                            // }
                        >
                            <AddIcon fontSize="small"/>
                            <Typography sx={{
                                marginLeft: '3px',
                                textTransform: 'none',
                                fontSize: '18px',
                            }}>New</Typography>
                        </Button>
                    </Box>
                </CardContent>
                <Divider />
                <CardContent>
                    <Box>

                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {
                                    headCells.map(headCell => (
                                        <TableCell key={headCell.id} width={headCell.width} align={headCell.align || undefined}>
                                            {
                                                headCell.sortable ?
                                                    <TableSortLabel
                                                        direction={sortOrder}
                                                        onClick={() => {
                                                            if (orderBy !== headCell.id) {
                                                                // @ts-ignore
                                                                setOrderBy(headCell.id)
                                                                setSortOrder("asc");
                                                            } else {
                                                                setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                                                            }
                                                        }}
                                                    >
                                                        <Typography variant="body2">
                                                            {headCell.label}
                                                        </Typography>
                                                    </TableSortLabel> :
                                                    <Typography variant="body2">
                                                        {headCell.label}
                                                    </Typography>
                                            }
                                        </TableCell>
                                    ))
                                }
                                {/* <TableCell width="20%">
                                    <Typography variant="body2">
                                        NAME
                                    </Typography>
                                </TableCell>
                                <TableCell width="15%">
                                    <Typography variant="body2">
                                        USER
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%%">
                                    <Typography variant="body2">
                                        STATUS
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%">
                                    <TableSortLabel
                                        direction={sortOrder}
                                        onClick={() => {setSortOrder(sortOrder === "asc" ? "desc" : "asc")}}
                                    >
                                        <Typography variant="body2">
                                            DATE SUBMITTED
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" width="25%">
                                    <Typography variant="body2">
                                        ACTIONS
                                    </Typography>
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sorted
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                <TableRow
                                    key={row.analysisId}
                                    sx={{
                                        ...((row.reportStatus && Number(row.reportStatus) === REPORT_STATUS.ARCHIVED
                                            || row.analysisStatus && Number(row.analysisStatus) === ANALYSIS_STATUS.ARCHIVED)
                                            && {display: 'none'}),
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                <TableCell component="th" scope="row">
                                    <Typography variant="body2">
                                        {row.reportName}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {row.username}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {
                                            row.reportStatus && `Report generation ${REPORT_STATUS_TEXT[row.reportStatus.toString() as keyof typeof REPORT_STATUS_TEXT]}`
                                            || row.analysisStatus && `Analysis ${ANALYSIS_STATUS_TEXT[row.analysisStatus.toString() as keyof typeof ANALYSIS_STATUS_TEXT]}`
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {
                                            `${row.dateSubmitted && formatDate(new Date(row.dateSubmitted)) || "Not submitted"}`
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Box>
                                        <Tooltip title="Edit analysis">
                                            <IconButton
                                                onClick={() => editAnalysis(row.analysisId)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        {classification.majorType === "AHVVA" && 
                                            <Tooltip title="View analysis">
                                                <span>
                                                <IconButton
                                                    onClick={() => viewAnalysis(row.analysisId)}
                                                    disabled={
                                                        row.analysisStatus && Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE
                                                        || false
                                                    }
                                                >
                                                    <ArrowForwardIcon fontSize="small" />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                        }
                                        <Tooltip title={`${!row.reportSubmitted || classification.majorType === "TERA" ? 'G' : 'Reg'}enerate report`}>
                                            <span>
                                            <IconButton
                                                onClick={() => generateReport(row.reportName, row.analysisId, row.classificationId )}
                                                disabled={
                                                    tempDisable ||
                                                    Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE ||
                                                    row.reportStatus !== null && Number(row.reportStatus) !== REPORT_STATUS.COMPLETE ||
                                                    row.reportSubmitted !== null && classification.majorType === "TERA" // Cannot regenerate if TERA
                                                }
                                            >
                                                {!row.reportSubmitted || classification.majorType === "TERA" ? 
                                                    <NoteAddIcon fontSize="small" /> :
                                                    <RestorePageIcon fontSize="small" />
                                                }
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Download report">
                                            <span>
                                            <IconButton
                                                onClick={() => downloadReport(row.reportId)}
                                                disabled={
                                                    tempDisable ||
                                                    Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE ||
                                                    row.reportStatus !== null && Number(row.reportStatus) !== REPORT_STATUS.COMPLETE ||
                                                    row.reportStatus === null
                                                }
                                            >
                                                <FileDownloadIcon fontSize="small" />
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                        {/* <Button
                                            sx={{
                                                marginLeft: '4px'
                                            }}
                                            variant="outlined"
                                            size="small"
                                            disabled={
                                                Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE ||
                                                row.reportStatus !== null && Number(row.reportStatus) !== REPORT_STATUS.COMPLETE
                                            }
                                            onClick={() => generateReport(row.reportName, row.analysisId)}
                                        >
                                            <Typography sx={{
                                                marginLeft: '3px',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                            }}>
                                                {`${row.reportSubmitted ? 'Reg' : 'G'}enerate report`}
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                marginLeft: '4px'
                                            }}
                                            variant="outlined"
                                            size="small"
                                            disabled={
                                                Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE ||
                                                row.reportStatus !== null && Number(row.reportStatus) !== REPORT_STATUS.COMPLETE
                                            }
                                            onClick={() => downloadReport(row.reportId)}
                                        >
                                            <Typography sx={{
                                                marginLeft: '3px',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                            }}>
                                                Download report
                                            </Typography>
                                        </Button> */}
                                        {/* {row.reportStatus !== null && Number(row.reportStatus) === REPORT_STATUS.COMPLETE ? 
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                disabled={
                                                    Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE
                                                }
                                                onClick={() => downloadReport(row.reportId)}
                                            >
                                                <Typography sx={{
                                                    marginLeft: '3px',
                                                    textTransform: 'none',
                                                    fontSize: '12px',
                                                }}>
                                                    Download report
                                                </Typography>
                                            </Button> :
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                disabled={
                                                    Number(row.analysisStatus) !== ANALYSIS_STATUS.COMPLETE ||
                                                    row.reportStatus !== null && Number(row.reportStatus) !== REPORT_STATUS.COMPLETE
                                                }
                                                onClick={() => generateReport(row.reportName, row.analysisId)}
                                            >
                                                <Typography sx={{
                                                    marginLeft: '3px',
                                                    textTransform: 'none',
                                                    fontSize: '12px',
                                                }}>
                                                    Generate report
                                                </Typography>
                                            </Button>
                                        } */}
                                    </Box>
                                </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Divider />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
};

export default ReportOverviewCard;